export const INVALID_EMAIL_ERROR = 'invalidEmailError';
export const EMAIL_CONFIRM_ERROR = 'emailConfirmError';
export const INVALID_DATE_ERROR = 'invalidDateError';
export const REQUIRED_FIELD_ERROR = 'requiredFieldError';
export const INVALID_MONTH_ERROR = 'invalidMonthError';
export const INVALID_FOUR_ZIPCODE_ERROR = 'invalidFourZipcodeError';
export const INVALID_FIVE_ZIPCODE_ERROR = 'invalidFiveZipcodeError';
export const INVALID_SELECT_ID_TYPE_ERROR = 'invalidUnselectedIdTypeError';
export const INVALID_ID_NUMBER_ERROR = 'invalidIdNumberError';
export const INVALID_PHONE_NUMBER = 'invalidPhoneNumberError';
export const INVALID_COUNTRY_ERROR = 'invalidCountryError';
export const EMPTY_BIRTHDATE_FIELD_ERROR = 'fillBirthDateFieldsError';
