import styled from 'styled-components';

import { getThemeColorWithError, getThemeTransition } from '@utils/styled';

import { Check, Cross } from '@common/components/Icons';
import Typography from '@common/components/Typography';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 6)};
`;

// TODO: update colors to align with styleguide - awaiting abstract
export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  color: ${getThemeColorWithError('bluegrey.40', 'bluegrey.70', 'isDone')};
  transition: ${getThemeTransition('color')};
`;

export const Message = styled(Typography).attrs({
  customAs: 'span',
  variant: 'body3',
})`
  flex: 1 1 auto;
  margin: 0;
  color: inherit;
`;

export const Icon = styled.span`
  display: inline-flex;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  color: inherit;

  svg {
    color: inherit;
    fill: currentColor;
  }
`;

export const SuccessIcon = styled(Check)`
  color: inherit;
  fill: currentColor;
`;

export const ErrorIcon = styled(Cross)`
  color: inherit;
  fill: currentColor;
`;
