import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { useTranslation } from 'next-i18next';

import { Close, Info } from '@common/components/Icons';

import {
  ErrorTooltip,
  SimpleErrorMessage,
  ErrorTooltipOpenButton,
  ErrorTooltipCloseButton,
  ErrorTooltipText,
} from './ErrorBox.styled';

const ErrorWithTooltip = ({ error }) => {
  const { t } = useTranslation();
  const errorWrapperRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isTooltipVisible, setTooltipVisibility] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (!errorWrapperRef.current.contains(target)) {
        setTooltipVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <div ref={errorWrapperRef}>
      <SimpleErrorMessage
        withIcon
        ref={setReferenceElement}
        onClick={() => setTooltipVisibility(prev => !prev)}
        role="button"
      >
        {t('defaultError.specificErrorTooltipShortcut', 'Fehler')}
        <ErrorTooltipOpenButton
          data-testid="specificErrorTooltipShortcut"
          {...attributes.popper}
          isTooltipVisible={isTooltipVisible}
        >
          <Info />
        </ErrorTooltipOpenButton>
      </SimpleErrorMessage>

      {isTooltipVisible && (
        <ErrorTooltip
          data-testid="specificErrorTooltip"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <ErrorTooltipText>{error}</ErrorTooltipText>
          <ErrorTooltipCloseButton
            type="button"
            onClick={() => setTooltipVisibility(false)}
          >
            <Close />
          </ErrorTooltipCloseButton>
        </ErrorTooltip>
      )}
    </div>
  );
};

ErrorWithTooltip.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorWithTooltip;
