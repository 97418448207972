import styled from 'styled-components';

import {
  getThemeColor,
  getThemeColorWithError,
  getThemeTransition,
} from '@utils/styled';

import Typography from '@common/components/Typography';

export const Container = styled.div`
  position: relative;
  flex: 1 1 auto;
  ${({ theme, noSpacing }) =>
    !noSpacing && theme.getFluidSpacing('margin-bottom', 'scale', 5)}
`;

export const Field = styled.div`
  display: flex;
`;

export const Label = styled.label`
  display: block;
  pointer-events: none;

  ${Field} {
    pointer-events: auto;
  }

  input {
    pointer-events: auto;
  }
`;

export const LabelRow = styled.span`
  display: block;
`;

export const LabelText = styled(Typography).attrs({
  customAs: 'span',
  variant: 'paragraph2',
})`
  display: inline-block;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 6)};
  color: ${getThemeColor('bluegrey.60')};
  white-space: nowrap;
  transition: ${getThemeTransition('color')};
`;

export const OptionalText = styled(Typography).attrs({
  customAs: 'span',
  variant: 'body3',
})`
  display: inline-block;
  margin-bottom: 0;
  margin-left: 5px;
  color: ${getThemeColor('bluegrey.80')};
`;

export const Message = styled(Typography).attrs({
  customAs: 'span',
  variant: 'body3',
})`
  display: inline-block;
  margin-bottom: 0;
  color: ${getThemeColorWithError('bluegrey.60', 'hue.red')};
  transition: ${getThemeTransition('color')};
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 6)}
`;
