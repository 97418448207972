import get from 'lodash/get';
import isArray from 'lodash/isArray';

import {
  EMAIL_CONFIRM_ERROR,
  EMPTY_BIRTHDATE_FIELD_ERROR,
  INVALID_COUNTRY_ERROR,
  INVALID_DATE_ERROR,
  INVALID_EMAIL_ERROR,
  INVALID_FIVE_ZIPCODE_ERROR,
  INVALID_FOUR_ZIPCODE_ERROR,
  INVALID_ID_NUMBER_ERROR,
  INVALID_MONTH_ERROR,
  INVALID_PHONE_NUMBER,
  INVALID_SELECT_ID_TYPE_ERROR,
  REQUIRED_FIELD_ERROR,
} from '@common/constants/formValidation';

export const yupToFormErrors = yupError => {
  let errors = {};

  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return { [yupError.path]: yupError.message };
    }

    errors = yupError.inner.reduce((newErrors, err) => {
      // eslint-disable-next-line no-param-reassign
      newErrors[err.path] = [...(newErrors[err.path] || []), err.message];

      return newErrors;
    }, {});
    // eslint-disable-next-line array-callback-return
    Object.entries(errors).map(([key, error]) => {
      errors[key] = error.length === 1 ? error[0] : error;
    });
  }

  return errors;
};

const defaultErrorsMap = {
  [EMAIL_CONFIRM_ERROR]: {
    defaultValue: 'Invalid confirm email',
    key: 'defaultError.emailMatchError',
  },
  [INVALID_DATE_ERROR]: {
    defaultValue: 'Invalid date',
    key: 'defaultError.invalidDateError',
  },
  [INVALID_EMAIL_ERROR]: {
    defaultValue: 'Invalid email',
    key: 'defaultError.invalidEmailError',
  },
  [INVALID_FIVE_ZIPCODE_ERROR]: {
    defaultValue: 'Zipcode require 5 digits',
    key: 'defaultError.invalidFiveZipcodeError',
  },
  [INVALID_FOUR_ZIPCODE_ERROR]: {
    defaultValue: 'Zipcode require 4 digits',
    key: 'defaultError.invalidFourZipcodeError',
  },
  [INVALID_MONTH_ERROR]: {
    defaultValue: 'Invalid month',
    key: 'defaultError.invalidMonthError',
  },
  [REQUIRED_FIELD_ERROR]: {
    defaultValue: 'This field is required',
    key: 'defaultError.requiredFieldError',
  },
  [INVALID_SELECT_ID_TYPE_ERROR]: {
    defaultValue: 'You need to select id type',
    key: 'defaultError.requiredFieldIdType',
  },
  [INVALID_ID_NUMBER_ERROR]: {
    defaultValue: 'Invalid ID number',
    key: 'defaultError.invalidIdNumberError',
  },
  [INVALID_PHONE_NUMBER]: {
    defaultValue: 'Invalid Phone Number Error',
    key: 'defaultError.invalidPhoneNumberError',
  },
  [INVALID_COUNTRY_ERROR]: {
    defaultValue: 'Invalid Country Error',
    key: 'defaultError.invalidCountryError',
  },
  [EMPTY_BIRTHDATE_FIELD_ERROR]: {
    defaultValue: 'Fill all birth date fields',
    key: 'defaultError.fillBirthDateFieldsError',
  },
};

export function getErrorMessage(t, error, customErrorsMap = {}) {
  let firstError = error;

  if (Array.isArray(error)) {
    [firstError] = error;
  }

  if (Object.prototype.hasOwnProperty.call(customErrorsMap, firstError)) {
    return t(
      customErrorsMap[firstError].key,
      customErrorsMap[firstError].options ||
        customErrorsMap[firstError].defaultValue ||
        undefined,
    );
  }

  if (Object.prototype.hasOwnProperty.call(defaultErrorsMap, firstError)) {
    return t(
      defaultErrorsMap[firstError].key,
      defaultErrorsMap[firstError].options ||
        defaultErrorsMap[firstError].defaultValue ||
        undefined,
    );
  }

  return firstError;
}

export function apiErrorsToFieldErrors(apiErrors, fieldsMap) {
  return Object.entries(fieldsMap).reduce((errors, [path, fieldName]) => {
    if (get(apiErrors, path)) {
      const error = get(apiErrors, path);

      return { ...errors, [fieldName]: isArray(error) ? error[0] : error };
    }

    return errors;
  }, apiErrors);
}
