import styled, { css } from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';

export const SimpleErrorMessage = styled(Typography).attrs({
  customAs: 'span',
  variant: 'body3',
})`
  display: block;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 6)};
  margin-bottom: 0;
  color: ${getThemeColor('hue.red')};

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: visible;
    `}
`;

export const ErrorTooltip = styled.div`
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 2.5rem;
  grid-gap: 1rem;
  width: 27rem;
  max-width: 90vw;
  box-shadow: ${({ theme }) => `${theme.shadows[2]}, ${theme.shadows[3]}`};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  padding: 2rem;
  padding-top: 1.6rem;
  padding-bottom: 1.5rem;
  background-color: white;

  &[data-popper-placement^='bottom'] {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  &[data-popper-placement^='top'] {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;

export const ErrorTooltipText = styled(Typography).attrs({
  customAs: 'span',
  variant: 'body2',
})`
  margin: 0;
  margin-top: 0.2rem;
`;

export const ErrorTooltipCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  margin: 0;
  border: none;
  border-radius: 50%;
  padding: 0;
  background-color: ${getThemeColor('bluegrey.90')};
  color: ${getThemeColor('bluegrey.60')};
  cursor: pointer;

  svg {
    width: 1.1rem;
    height: 1.1rem;
    margin: auto;
  }
`;

export const ErrorTooltipOpenButton = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-left: 0.5rem;
  border: none;
  padding: 0;
  background-color: ${getThemeColor('grey.100')};
  cursor: pointer;

  svg {
    fill: ${getThemeColor('hue.red')};
    width: 2rem;
    height: 2rem;
  }

  ${({ isTooltipVisible }) =>
    isTooltipVisible &&
    css`
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        z-index: 11;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top: none;
        border-bottom-color: ${getThemeColor('grey.100')};
        transform: translateX(-50%);
      }

      &[data-popper-placement^='bottom']::after {
        bottom: -1.1rem;
      }

      &[data-popper-placement^='top']::after {
        top: -1.1rem;
        transform: translateX(-50%) rotate(180deg);
      }
    `}
`;
