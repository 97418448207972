import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import ErrorBox from '@common/components/forms/ErrorBox';

import { RequirementsList } from './RequirementsList';
import {
  Container,
  Field,
  Label,
  LabelRow,
  LabelText,
  OptionalText,
} from './FormControl.styled';

const FormControl = ({
  children,
  className,
  message,
  label,
  labelAs,
  hasError,
  noSpacing,
  optional,
  optionalText,
  requirements,
  htmlFor,
}) => {
  const { t } = useTranslation();
  const renderOptionalHint = () => (
    <OptionalText hasError={hasError}>
      {optionalText || t('global.form.label.optional', 'Optional')}
    </OptionalText>
  );
  const renderLabel = () => <LabelText hasError={hasError}>{label}</LabelText>;
  const as = label ? 'label' : 'div';

  return (
    <Container className={className} noSpacing={noSpacing}>
      <Label as={labelAs || as} htmlFor={htmlFor}>
        {label && (
          <LabelRow>
            {typeof label === 'string' ? renderLabel() : label}
            {optional && renderOptionalHint()}
          </LabelRow>
        )}
        <Field>{children}</Field>
      </Label>
      {message && <ErrorBox error={message} />}
      {!!requirements?.length && (
        <RequirementsList requirements={requirements} />
      )}
    </Container>
  );
};

FormControl.defaultProps = {
  className: undefined,
  hasError: false,
  htmlFor: undefined,
  label: null,
  labelAs: undefined,
  message: null,
  noSpacing: false,
  optional: false,
  optionalText: null,
  requirements: null,
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  labelAs: PropTypes.elementType,
  message: PropTypes.node,
  noSpacing: PropTypes.bool,
  optional: PropTypes.bool,
  optionalText: PropTypes.string,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      isDone: PropTypes.bool,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      message: PropTypes.node.isRequired,
    }),
  ),
};

export { FormControl };
