import styled from 'styled-components';

import { getThemeTransition, getThemeColor } from '@utils/styled';

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Switch = styled.div`
  position: relative;
  display: inline-block;
  width: 4.4rem;
  height: 2.6rem;
`;

export const Slider = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.borderRadiuses.pill}rem;
  background-color: ${getThemeColor('bluegrey.95')};
  transition: ${getThemeTransition('all')};

  &::before {
    content: '';
    position: absolute;
    width: 2.2rem;
    height: 2.2rem;
    box-shadow: ${({ theme }) => theme.shadows[0]};
    border-radius: ${({ theme }) => theme.borderRadiuses.circle};
    background-color: ${({ isDisabled }) =>
      getThemeColor(isDisabled ? 'grey.85' : 'grey.100')};
  }

  &:focus {
    ::before {
      transition: ${getThemeTransition('all')};
    }
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  + ${Slider} {
    border: 0.2rem solid ${getThemeColor('bluegrey.90')};
  }

  &:checked + ${Slider} {
    border: 0.2rem solid ${getThemeColor('hue.green')};
    background-color: ${getThemeColor('hue.green')};

    &::before {
      transform: translateX(1.8rem);
    }
  }
`;

export const TogglesWrapper = styled.div`
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 1)};
`;
