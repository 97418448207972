import PropTypes from 'prop-types';
import React from 'react';

import {
  ErrorIcon,
  Icon,
  List,
  Message,
  Row,
  SuccessIcon,
} from './RequirementsList.styled';

const RequirementsList = ({ requirements }) => (
  <List>
    {requirements.map(({ key, isDone, message }) => (
      <Row key={key} isDone={isDone} data-testid="requirement-rule">
        <Icon>
          {isDone ? (
            <SuccessIcon data-testid="requirement-rule-icon-success" />
          ) : (
            <ErrorIcon data-testid="requirement-rule-icon-error" />
          )}
        </Icon>
        <Message>{message}</Message>
      </Row>
    ))}
  </List>
);

RequirementsList.propTypes = {
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      isDone: PropTypes.bool,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      message: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export { RequirementsList };
