import PropTypes from 'prop-types';
import React from 'react';

import { LabelText } from '@common/components/forms/FormControl';

import { Input, Label, Slider, Switch } from './SimpleToggle.styled';

export const SimpleToggle = ({
  className,
  disabled,
  id,
  label,
  checked,
  onChange,
  ...props
}) => (
  <Label htmlFor={id} className={className} disabled={disabled}>
    {label && <LabelText>{label}</LabelText>}
    <Switch>
      <Input
        id={id}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <Slider isDisabled={disabled} />
    </Switch>
  </Label>
);

SimpleToggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

SimpleToggle.defaultProps = {
  checked: undefined,
  className: undefined,
  disabled: false,
  id: undefined,
  label: undefined,
  onChange: undefined,
};

export default SimpleToggle;
