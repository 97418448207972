import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { getErrorMessage } from '@utils/validation';

import { REQUIRED_FIELD_ERROR } from '@common/constants/formValidation';

import { SimpleErrorMessage } from './ErrorBox.styled';
import ErrorWithTooltip from './ErrorWithTooltip';

const ErrorBox = ({ error }) => {
  const { t } = useTranslation();
  const requiredErrorMessage = getErrorMessage(t, REQUIRED_FIELD_ERROR);

  return error === requiredErrorMessage ? (
    <SimpleErrorMessage>{error}</SimpleErrorMessage>
  ) : (
    <ErrorWithTooltip error={error} />
  );
};

ErrorBox.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorBox;
